<template lang="pug">
FSelect.FcLocaleSelect(
  :model-value="locale"
  :options="selectOptions"
  width="200"
  color="transparent"
  text-color="secondary--light-1"
  focus-color="transparent"
  border-color="secondary--light-1"
  focus-border-color="secondary--light-1"
  outline-color="secondary"
  options-menu-color="neutral--light-5"
  option-text-color="secondary"
  selected-option-color="neutral--light-3"
  selected-option-text-color="secondary"
  hide-hint
  @update:model-value="navigateToLocalePath"
)
  template(#selected-value="{ value, label }")
    .FcLocaleSelect__selectedValue
      FFlagIcon.FcLocaleSelect__option__img(
        :country-code="localeToCountryCodeMap[value]"
      )
      span {{ label }}
  template(#option-prefix="{ option }")
    FFlagIcon.FcLocaleSelect__option__img(
      :country-code="localeToCountryCodeMap[option.value]"
    )
  template(#option="{ option }")
    span {{ option.label }}
</template>

<style lang="stylus">
.FcLocaleSelect__selectedValue
  display flex

.FcLocaleSelect__option__img
  margin-right rem(12)
  height rem(24)
</style>

<script setup lang="ts">
import { FFlagIcon } from '@fifteen/design-system-vue';

import { locales } from '@/config/locales';

import type { FFlagIconProps } from '@fifteen/design-system-vue';

const localeToCountryCodeMap: Record<string, FFlagIconProps['countryCode']> = {
  fr: 'FR',
  en: 'GB',
  de: 'DE',
  es: 'ES',
  it: 'IT',
  pl: 'PL',
};

const { t } = useI18n();

const { locale } = useI18n();
const switchLocalePath = useSwitchLocalePath();

function navigateToLocalePath(value: string): void {
  navigateTo(switchLocalePath(value));
}

const selectOptions = computed(() =>
  locales.map(_locale => ({
    label: t(`app__lang_${_locale}`),
    value: _locale,
  }))
);
</script>
