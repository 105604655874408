import revive_payload_client_4sVQNw7RlN from "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_VdOeuw25Mb from "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/.nuxt/sentry-client-config.mjs";
import switch_locale_path_ssr_5csfIgkrBP from "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import _0_log_Akyu7rZDxz from "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/plugins/0.log.ts";
import _1_fds_0fgZfnM7dG from "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/plugins/1.fds.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  _0_log_Akyu7rZDxz,
  _1_fds_0fgZfnM7dG
]