<template lang="pug">
FRouterLink(
  v-bind="props"
  :location="i18nLocation"
  :target="target ?? (isExternal ? '_blank' : '_self')"
  :hreflang="i18nHreflang"
  @click="emit('click', $event)"
  @focus="emit('focus', $event)"
)
  slot
</template>

<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router';

export interface FcLinkProps {
  /**
   * Target location or url
   */
  location?: RouteLocationRaw | null;
  /**
   * Color of the link
   */
  color?: Color;
  /**
   * Color of the link on hover
   */
  hoverColor?: Color;
  /**
   * Color of the arrow
   */
  arrowColor?: Color;
  /**
   * Color of the arrow on hover
   */
  arrowHoverColor?: Color;
  /**
   * Use preventDefault on click event
   */
  prevent?: boolean;
  /**
   * Disable or not the link
   */
  disabled?: boolean;
  /**
   * Hide the arrow
   */
  hideArrow?: boolean;
  /**
   * Underline the link
   */
  underlined?: boolean;
  /**
   * Transform the link to uppercase
   */
  uppercase?: boolean;
  /**
   * Link target, overrides the one computed automatically on location
   */
  target?: HTMLAnchorElement['target'];
  /**
   * Link hreflang, overrides the one computed automatically on location
   */
  hreflang?: HTMLAnchorElement['hreflang'];
  /**
   * Role "button" (aria attribute) for a11y purposes
   */
  role?: 'button';
  /**
   * RouterLink-like active class
   */
  activeClass?: string | null;
  /**
   * RouterLink-link exact active class
   */
  exactActiveClass?: string | null;
  /**
   * RouterLink replace prop
   */
  replace?: boolean;
}

const props = withDefaults(defineProps<FcLinkProps>(), {
  location: null,
  color: 'secondary',
  hoverColor: 'primary',
  arrowColor: 'neutral--light-5',
  arrowHoverColor: 'neutral--light-5',
  target: undefined,
  hreflang: undefined,
  role: undefined,
  disabled: false,
  hideArrow: false,
  underlined: false,
  uppercase: false,
  activeClass: null,
  exactActiveClass: null,
  replace: false,
});

const emit = defineEmits<{
  (name: 'click', e: MouseEvent): void;
  (name: 'focus', e: Event): void;
}>();

const isExternal = computed(() =>
  /^(https?:\/\/|mailto:|tel:)/.test(props.location as string)
);

const { i18nPath, hreflang } = useI18nPath(() => props.location);

const i18nLocation = computed(() => {
  return isExternal.value ? props.location : i18nPath.value;
});

const i18nHreflang = computed(() => {
  return props.hreflang ?? hreflang.value;
});
</script>
