
// @ts-nocheck


export const localeCodes =  [
  "fr",
  "en",
  "de",
  "es",
  "it",
  "pl"
]

export const localeLoaders = {
  "fr": [{ key: "../locales/index.ts", load: () => import("../locales/index.ts?hash=cf91983b&locale=fr" /* webpackChunkName: "locale__tmp_build_ad8838f6_corporate_website_nuxt3_pr_locales_index_ts" */), cache: false }],
  "en": [{ key: "../locales/index.ts", load: () => import("../locales/index.ts?hash=cf91983b&locale=fr" /* webpackChunkName: "locale__tmp_build_ad8838f6_corporate_website_nuxt3_pr_locales_index_ts" */), cache: false }],
  "de": [{ key: "../locales/index.ts", load: () => import("../locales/index.ts?hash=cf91983b&locale=fr" /* webpackChunkName: "locale__tmp_build_ad8838f6_corporate_website_nuxt3_pr_locales_index_ts" */), cache: false }],
  "es": [{ key: "../locales/index.ts", load: () => import("../locales/index.ts?hash=cf91983b&locale=fr" /* webpackChunkName: "locale__tmp_build_ad8838f6_corporate_website_nuxt3_pr_locales_index_ts" */), cache: false }],
  "it": [{ key: "../locales/index.ts", load: () => import("../locales/index.ts?hash=cf91983b&locale=fr" /* webpackChunkName: "locale__tmp_build_ad8838f6_corporate_website_nuxt3_pr_locales_index_ts" */), cache: false }],
  "pl": [{ key: "../locales/index.ts", load: () => import("../locales/index.ts?hash=cf91983b&locale=fr" /* webpackChunkName: "locale__tmp_build_ad8838f6_corporate_website_nuxt3_pr_locales_index_ts" */), cache: false }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "i18n_config_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": false,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "fr",
      "files": [
        "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/locales/index.ts"
      ]
    },
    {
      "code": "en",
      "files": [
        "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/locales/index.ts"
      ]
    },
    {
      "code": "de",
      "files": [
        "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/locales/index.ts"
      ]
    },
    {
      "code": "es",
      "files": [
        "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/locales/index.ts"
      ]
    },
    {
      "code": "it",
      "files": [
        "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/locales/index.ts"
      ]
    },
    {
      "code": "pl",
      "files": [
        "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/locales/index.ts"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_and_default",
  "lazy": true,
  "langDir": "locales/",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "no prefix",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "fr",
    "files": [
      {
        "path": "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/locales/index.ts"
      }
    ]
  },
  {
    "code": "en",
    "files": [
      {
        "path": "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/locales/index.ts"
      }
    ]
  },
  {
    "code": "de",
    "files": [
      {
        "path": "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/locales/index.ts"
      }
    ]
  },
  {
    "code": "es",
    "files": [
      {
        "path": "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/locales/index.ts"
      }
    ]
  },
  {
    "code": "it",
    "files": [
      {
        "path": "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/locales/index.ts"
      }
    ]
  },
  {
    "code": "pl",
    "files": [
      {
        "path": "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/locales/index.ts"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
