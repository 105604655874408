import { init, browserTracingIntegration } from '@sentry/nuxt';

const config = useRuntimeConfig();

init({
  dsn: config.public.sentryDsn,
  integrations: [browserTracingIntegration()],
  tracePropagationTargets: [/^https:\/\/fifteen.eu/],
  sampleRate: config.public.sentrySampleRate ?? 1.0,
  tracesSampleRate: config.public.sentryTracesSampleRate ?? 1.0,
  debug: config.public.sentryDebug ?? false,
  environment: config.public.sentryEnvironment ?? 'development',
});
