import type {
  FNavbarProps,
  FNavbarCategory,
  FNavbarLink,
} from '@/components/organisms/FNavbar.vue';
import type {
  FFooterLink,
  FFooterProps,
} from '@/components/organisms/FFooter.vue';
import type { UseCmsNavigationReturn } from '@/composables/useCmsNavigation';

export function mapApiDataToNavigation(
  navigationRawData?: StrapiResponseData<NavigationContentType>[]
): UseCmsNavigationReturn {
  if (!navigationRawData)
    return {
      navbarNavigations: [],
      footerNavigations: [],
    };

  return {
    navbarNavigations: mapApiDataToNavbarNavigations(navigationRawData),
    footerNavigations: mapApiDataToFooterNavigations(navigationRawData),
  };
}

function mapApiDataToNavbarNavigations(
  navigationData: StrapiResponseData<NavigationContentType>[]
): FNavbarProps['navigations'] {
  return navigationData
    .sort((a, b) => (a.attributes?.rank ?? 0) - (b.attributes?.rank ?? 0))
    .map(navigation => ({
      title: navigation.attributes.title,
      ...(navigation.attributes.url && { location: navigation.attributes.url }),
      categories: mapApiItemGroupAndItemsToNavbarCategories(
        navigation.attributes.item_groups,
        navigation.attributes.items
      ),
      ...(navigation.attributes.bottom_link && {
        bottomLinkText: navigation.attributes.bottom_link.text,
        bottomLink: navigation.attributes.bottom_link.url,
      }),
    }));
}

function mapApiItemGroupAndItemsToNavbarCategories(
  itemGroups?: NavigationItemGroupComponent[],
  items?: NavigationItemComponent[]
): FNavbarCategory[] {
  return (itemGroups ?? [])
    .map(mapApiItemGroupToNavbarCategory)
    .concat(mapApiItemsToNavbarCategory(items) ?? []);
}

function mapApiItemGroupToNavbarCategory(
  itemGroup: NavigationItemGroupComponent
): FNavbarCategory {
  return {
    name: itemGroup.name,
    links: itemGroup.items.map(mapApiItemToNavbarLink),
  };
}

function mapApiItemsToNavbarCategory(
  items?: NavigationItemComponent[]
): FNavbarCategory | null {
  if (!items?.length) return null;
  return {
    links: items.map(mapApiItemToNavbarLink),
  };
}

function mapApiItemToNavbarLink(item: NavigationItemComponent): FNavbarLink {
  return {
    title: item.title,
    location: item.url,
    description: item.description ?? '',
    icon: item.icon,
    trending: item.trending,
  };
}

function mapApiDataToFooterNavigations(
  navigationData: StrapiResponseData<NavigationContentType>[]
): FFooterProps['navigations'] {
  return navigationData
    .sort((a, b) => (a.attributes?.rank ?? 0) - (b.attributes?.rank ?? 0))
    .map(navigation => ({
      title: navigation.attributes.title,
      location: navigation.attributes.url,
      links: mapApiItemGroupsAndItemsToFooterLinks(
        navigation.attributes.item_groups,
        navigation.attributes.items
      ),
    }));
}

function mapApiItemGroupsAndItemsToFooterLinks(
  itemGroups?: NavigationItemGroupComponent[],
  items?: NavigationItemComponent[],
  bottomLink?: NavigationBottomLinkComponent
): FFooterLink[] {
  return (itemGroups ?? [])
    .flatMap(itemGroup => {
      return itemGroup.items.map(mapApiItemToFooterLink);
    })
    .concat((items ?? []).map(mapApiItemToFooterLink))
    .concat(mapApiBottomLinkToFooterLink(bottomLink) ?? []);
}

function mapApiItemToFooterLink(item: NavigationItemComponent): FFooterLink {
  return {
    text: item.title,
    location: item.url,
  };
}
function mapApiBottomLinkToFooterLink(
  bottomLink?: NavigationBottomLinkComponent
): FFooterLink | null {
  if (!bottomLink) return null;
  return {
    text: bottomLink.text,
    location: bottomLink.url,
  };
}
