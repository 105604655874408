import { getSafeImageSize } from '@/lib/utils';

import type { FImageProps } from '@fifteen/design-system-vue';
import type { FCaseStudyCardProps } from '@/components/molecules/FCaseStudyCard.vue';

export function mapApiImageToImageProps(
  rawImageData?: StrapiImage['data']['attributes'],
  size?: StrapiImageSize
): FImageProps {
  // Filter on .jpeg image only as Strapi seems to handle badly the compression of .png
  const isJpegImage = ['.jpeg', '.jpg'].includes(rawImageData?.ext ?? '');

  const imageSize = getSafeImageSize(size, rawImageData?.formats ?? {});
  // Try to get placeholder format. If it does not exist, fallback to the closest format (thumbnail)
  const imagePlaceholder = getSafeImageSize(
    'placeholder',
    rawImageData?.formats ?? {}
  );

  const imageSource =
    isJpegImage && imageSize
      ? rawImageData?.formats[imageSize]
      : rawImageData ?? { url: '', width: 0, height: 0 };

  return {
    src: imageSource?.url ?? '',
    alt: rawImageData?.alternativeText ?? '',
    placeholder: imagePlaceholder
      ? rawImageData?.formats?.[imagePlaceholder]?.url
      : '',
    width: imageSource?.width,
    height: imageSource?.height,
  };
}

export function mapUseCaseToCaseStudyCard(
  useCase: UseCaseContentType
): FCaseStudyCardProps {
  return {
    title: useCase?.city_name ?? '',
    text: useCase.service_description ?? '',
    launchDate: useCase.launch_date,
    kpis: (useCase.service_kpis ?? []).map(kpi => ({
      value: kpi.value ?? 0,
      caption: kpi.caption ?? '',
      suffix: kpi.suffix ?? '',
      prefix: kpi.prefix ?? '',
    })),
    backgroundImageSrc: mapApiImageToImageProps(
      useCase.city_image?.data?.attributes
    ).src,
    link: { location: useCase.redirect_url ?? `/case-studies/${useCase.slug}` },
  };
}
