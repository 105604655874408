import { createFds } from '@fifteen/design-system-vue';
import * as icons from '@fifteen/design-system-vue/icons';
import * as flagIcons from '@fifteen/design-system-vue/icons/flags';

/**
 * Plugin that init fds
 */
export default defineNuxtPlugin(async () => {
  const { vueApp } = useNuxtApp();
  const fds = createFds({
    icons, // As all icons can be used in the CMS, we must import the whole collection
    flagIcons,
  });
  vueApp.use(fds);
});
