export const redirections = {
  '/product': '/augmented-bike-networks',
};

export const smooveRedirections: Record<string, string> = {
  'https://www.smoove.fr/newsroom': 'news',
  'https://www.smoove.fr/actualites/smoove-devient-fifteen':
    '/fr/resources/blog/smoove-et-zoov-deviennent-fifteen',
  'https://www.smoove.fr/nos-solutions': '/augmented-bike-networks',
  'https://www.smoove.fr/sabonner-la-newsletter-smoove': 'legal',
  'https://www.smoove.fr/contact': 'contact',
  'https://www.smoove.fr/expertises': '/augmented-bike-networks',
  'https://www.smoove.fr/actualites/fusion':
    '/fr/resources/blog/smoove-et-zoov-deviennent-fifteen',
  'https://www.smoove.fr/actualites/smoove-et-zoov-unissent-leurs-forces':
    '/fr/resources/blog/smoove-et-zoov-deviennent-fifteen',
  'https://www.smoove.fr/epinal':
    '/fr/resources/blog/le-succes-du-vls-electrique-en-zone-peu-dense-vilvolt?utm_source=press&utm_medium=vrtweb&utm_campaign=event_202206_awareness',
  'https://www.smoove.fr/landerneau':
    '/fr/resources/blog/landerneau-choisit-fifteen-pour-deployer-des-velos-electriques-en-libre-service',

  'https://www.smoove-bike.com/contact-us': '/contact',
  'https://www.smoove-bike.com/our-solutions': '/augmented-bike-networks',
  'https://www.smoove-bike.com/news': '/news',
  'https://www.smoove-bike.com/legal-mentions': '/legal',
  'https://www.smoove-bike.com/newsroom-en': '/news',
  'https://www.smoove-bike.com/expertise': '/augmented-bike-networks',
  'https://www.smoove-bike.com/news/we-are-now-fifteen':
    '/en/news/smoove-and-zoov-become-fifteen',
  'https://www.smoove-bike.com/news/we-are-now-fifteen-0':
    '/en/news/smoove-and-zoov-become-fifteen',
  'https://www.smoove-bike.com/news/smoove-and-zoov-join-forces':
    '/en/news/smoove-and-zoov-become-fifteen',
  'https://www.smoove-bike.com/newsroom': '/news',

  'https://www.smoove-bici.it/': '/it',
  'https://www.smoove.ru/': '/ru',
  'https://www.smoove-bike.de': '/de',
  'https://www.smoove-rower.pl': '/pl',
  'https://www.smoove.es': '/es',
  'https://www.smoove.mx': '/es',

  default: '/',
};
