import { fallbackLocale } from '@/config/locales';
/**
 * Composable that returns the client locale if it does exist on Strapi, or the Strapi default locale
 * @param localesSubset - Restrict the response to a given subset of locales
 */
export async function useStrapiLocale(
  localesSubset?: string[]
): Promise<Ref<StrapiLocale | null>> {
  const config = useRuntimeConfig();
  const { locale } = useI18n();

  const { data, error } = await useFetch<StrapiLocaleResponse[]>(
    '/i18n/locales',
    { baseURL: config.public.strapiBaseUrl }
  );

  const strapiLocale = computed<StrapiLocale | null>(() => {
    if (error.value) return null;

    const defaultLocale: StrapiLocale =
      (data.value?.find(locale => locale.isDefault)?.code as StrapiLocale) ??
      fallbackLocale;

    const strapiLocales = (data.value ?? []).map(locale => locale.code);
    const restrictedLocales = localesSubset
      ? strapiLocales.filter(locale => localesSubset.includes(locale))
      : strapiLocales;

    return restrictedLocales.includes(locale.value)
      ? (locale.value as StrapiLocale)
      : defaultLocale;
  });

  return strapiLocale;
}
