/**
 * Allow to fallback on smaller image size if the requested format does not exist
 * @param size - A Strapi image size
 * @param imageFormats - Formats for the image
 * @returns Closest image size to the asked one
 */
export function getSafeImageSize(
  size: StrapiImageSize | undefined,
  imageFormats: StrapiImageFormats
): StrapiImageSize | null {
  if (!size) return null;

  const imageFormatsOrder: StrapiImageSize[] = [
    'placeholder',
    'thumbnail',
    'small',
    'medium',
    'large',
  ];

  const imageSizeOrder = imageFormatsOrder.findIndex(
    imageFormat => imageFormat === size
  );

  const imageSizes = Object.keys(imageFormats ?? {});

  const isFormatAvailable = imageSizes.includes(size);
  if (isFormatAvailable) return size;

  const lookingOrder = [
    ...imageFormatsOrder.slice(imageSizeOrder + 1, imageFormatsOrder.length),
    ...imageFormatsOrder.slice(0, imageSizeOrder + 1).reverse(),
  ];

  return lookingOrder.find(size => imageSizes.includes(size)) ?? null;
}
