<template lang="pug">
FLogo.FWatermarkLogo(
  :style="style"
  :color="color"
  :variant="logo"
  :width="width"
)
</template>

<style lang="stylus">
.FWatermarkLogo
  position absolute
  z-index var(--FWatermarkLogo--zIndex)
  top var(--FWatermarkLogo--top)
  bottom var(--FWatermarkLogo--bottom)
  left var(--FWatermarkLogo--left)
  right var(--FWatermarkLogo--right)
  opacity var(--FWatermarkLogo--opacity)
</style>

<script setup lang="ts">
import type { FLogoVariant } from '@fifteen/design-system-vue';

export interface FWatermarkLogoProps {
  /**
   * Logo used for the watermark
   */
  logo: FLogoVariant;
  /**
   * Color of the logo
   */
  color?: Color;
  /**
   * Width of the logo
   */
  width?: string | number;
  /**
   * Opcacity of the logo
   */
  opacity?: number;
  /**
   * z-index of the logo
   */
  zIndex?: number | string;
  /**
   * Left position of the logo
   */
  left?: string | number;
  /**
   * Right position of the logo
   */
  right?: string | number;
  /**
   * Top position of the logo
   */
  top?: string | number;
  /**
   * Bottom position of the logo
   */
  bottom?: string | number;
}

const props = withDefaults(defineProps<FWatermarkLogoProps>(), {
  color: 'success',
  width: '500px',
  opacity: 0.2,
  zIndex: -1,
  left: '',
  right: '',
  top: '',
  bottom: '',
});

const style = computed(
  (): Style => ({
    '--FWatermarkLogo--opacity': String(props.opacity),
    '--FWatermarkLogo--zIndex': String(props.zIndex),
    '--FWatermarkLogo--left': genSize(props.left),
    '--FWatermarkLogo--right': genSize(props.right),
    '--FWatermarkLogo--top': genSize(props.top),
    '--FWatermarkLogo--bottom': genSize(props.bottom),
  })
);
</script>
