import {
  initFacebookTracking,
  initHubspotTracking,
  initLinkedinTracking,
} from '@/assets/tracking';

/**
 * Composable that initialize axeptio SDK and register callbacks to init tracking
 */
export function useTracking(): void {
  if (import.meta.client) {
    const config = useRuntimeConfig();
    const { locale } = useI18n();
    const cookiesComplete = ref(false);
    // We must override process.env to avoid a React error on axeptio side
    if (window.process) window.process.env = {};
    // Only instantiate axeptio if it's not already instantiated (HMR)
    if (!window.axeptioSDK) {
      window.axeptioSettings = {
        clientId: config.public.axeptioClientId,
        cookiesVersion: 'fifteen-' + locale.value,
      };
      const axeptioScriptEl = document.createElement('script');
      axeptioScriptEl.type = 'text/javascript';
      axeptioScriptEl.src = 'https://static.axept.io/sdk-slim.js';
      axeptioScriptEl.async = true;
      if (document.body) document.body.appendChild(axeptioScriptEl);
      (window._axcb = window._axcb || []).push(sdk => {
        sdk.on('cookies:complete', choices => {
          cookiesComplete.value = true;
          if (choices.Linkedin) initLinkedinTracking();
          if (choices.facebook_pixel) initFacebookTracking();
          if (choices.hubspot) initHubspotTracking();
        });
      });
    }
    // Whenever language changes, it cookie consent has not been complete yet,
    // we reload window to force axeptio to reload its module in the correct language
    watch(locale, () => {
      if (!cookiesComplete.value) window.location.reload();
    });
  }
}
