import { locales } from '@/config/locales';
import { mapApiDataToNavigation } from '@/lib/cms-mappers';

import type { FFooterProps } from '@/components/organisms/FFooter.vue';
import type { FNavbarProps } from '@/components/organisms/FNavbar.vue';

const populateFields = [
  'item_groups',
  'item_groups.items',
  'items',
  'bottom_link',
];

export interface UseCmsNavigationReturn {
  /**
   * Navigations for the navbar
   */
  navbarNavigations: FNavbarProps['navigations'];
  /**
   * Navigations for the footer
   */
  footerNavigations: FFooterProps['navigations'];
}
/**
 * Composable that returns navigations from strapi for the app
 */
export async function useCmsNavigation(): UseCmsResponse<UseCmsNavigationReturn> {
  const { data: navigationData } = await useStrapi('navigations', {
    localesSubset: locales,
    queryParams: {
      populate: populateFields.join(','),
    },
  });

  const mappedData = computed(() =>
    mapApiDataToNavigation(navigationData.value?.data)
  );

  return {
    data: mappedData,
  };
}
