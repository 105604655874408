import { mapApiImageToImageProps } from './common.mappers';

import type { FEmbedPageTemplateProps } from '@/components/templates/FEmbedPageTemplate.vue';

export interface EmbedPage {
  title?: string;
  description?: string;
  imageUrl?: string;
  templateProps: FEmbedPageTemplateProps;
  locale: StrapiLocale;
}

export function mapApiDataToEmbedPage(
  rawData: StrapiResponseData<EmbedPageContentType>[] | undefined
): EmbedPage | null {
  const dataAttributes = rawData?.[0]?.attributes;
  if (!dataAttributes) return null;

  return {
    title: dataAttributes.title,
    description: dataAttributes.description,
    imageUrl: mapApiImageToImageProps(
      dataAttributes?.image?.data?.attributes,
      'large'
    )?.src,
    templateProps: {
      url: dataAttributes.url,
      backgroundColor: dataAttributes.background_color,
      showNavBar: dataAttributes.show_nav_bar,
    },
    locale: dataAttributes.locale,
  };
}
