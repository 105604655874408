import { default as augmented_45bike_45networkswniM8G0o5NMeta } from "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/augmented-bike-networks.vue?macro=true";
import { default as bike_45trainzglI1dlaxaMeta } from "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/bike-train.vue?macro=true";
import { default as _91slug_93ri2DYrczfyMeta } from "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/case-studies/[slug].vue?macro=true";
import { default as indexpJfK6XBXkLMeta } from "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/case-studies/index.vue?macro=true";
import { default as cities24aTMLPTzIMeta } from "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/cities.vue?macro=true";
import { default as contact9rZjifUSStMeta } from "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/contact.vue?macro=true";
import { default as electric_45bikeQ7RjJKQMTxMeta } from "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/electric-bike.vue?macro=true";
import { default as indexq1XQiJkWPzMeta } from "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/index.vue?macro=true";
import { default as confirmationbW62zJ0AorMeta } from "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/internal/confirmation.vue?macro=true";
import { default as loaderiatEv1QpaNMeta } from "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/internal/loader.vue?macro=true";
import { default as _91slug_9341mM200tSyMeta } from "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/landing/[slug].vue?macro=true";
import { default as leasingnhYM1wb6wnMeta } from "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/leasing.vue?macro=true";
import { default as legalLedmQ6io50Meta } from "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/legal.vue?macro=true";
import { default as _91slug_9364Cd1EvkdzMeta } from "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/news/[slug].vue?macro=true";
import { default as indexcX2mIF1tw9Meta } from "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/news/index.vue?macro=true";
import { default as operatorspeJAQHcypFMeta } from "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/operators.vue?macro=true";
import { default as privacyDDOOWi0fX6Meta } from "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/privacy.vue?macro=true";
import { default as _91slug_93BB3bIrAwJHMeta } from "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/resources/blog/[slug].vue?macro=true";
import { default as indexU6xmuwKQjhMeta } from "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/resources/blog/index.vue?macro=true";
import { default as _91slug_93RefDYLuWVaMeta } from "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/resources/guides/[slug].vue?macro=true";
import { default as indexU3vIUEBeftMeta } from "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/resources/guides/index.vue?macro=true";
import { default as indexpJfdn6OQdZMeta } from "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/resources/index.vue?macro=true";
import { default as sharingpBlnubz0DKMeta } from "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/sharing.vue?macro=true";
import { default as smart_45stationt5cQ1fohEgMeta } from "/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/smart-station.vue?macro=true";
export default [
  {
    name: "augmented-bike-networks___fr",
    path: "/fr/augmented-bike-networks",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/augmented-bike-networks.vue")
  },
  {
    name: "augmented-bike-networks___en___default",
    path: "/augmented-bike-networks",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/augmented-bike-networks.vue")
  },
  {
    name: "augmented-bike-networks___en",
    path: "/en/augmented-bike-networks",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/augmented-bike-networks.vue")
  },
  {
    name: "augmented-bike-networks___de",
    path: "/de/augmented-bike-networks",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/augmented-bike-networks.vue")
  },
  {
    name: "augmented-bike-networks___es",
    path: "/es/augmented-bike-networks",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/augmented-bike-networks.vue")
  },
  {
    name: "augmented-bike-networks___it",
    path: "/it/augmented-bike-networks",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/augmented-bike-networks.vue")
  },
  {
    name: "augmented-bike-networks___pl",
    path: "/pl/augmented-bike-networks",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/augmented-bike-networks.vue")
  },
  {
    name: "bike-train___fr",
    path: "/fr/bike-train",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/bike-train.vue")
  },
  {
    name: "bike-train___en___default",
    path: "/bike-train",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/bike-train.vue")
  },
  {
    name: "bike-train___en",
    path: "/en/bike-train",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/bike-train.vue")
  },
  {
    name: "bike-train___de",
    path: "/de/bike-train",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/bike-train.vue")
  },
  {
    name: "bike-train___es",
    path: "/es/bike-train",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/bike-train.vue")
  },
  {
    name: "bike-train___it",
    path: "/it/bike-train",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/bike-train.vue")
  },
  {
    name: "bike-train___pl",
    path: "/pl/bike-train",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/bike-train.vue")
  },
  {
    name: "case-studies-slug___fr",
    path: "/fr/case-studies/:slug()",
    meta: _91slug_93ri2DYrczfyMeta || {},
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/case-studies/[slug].vue")
  },
  {
    name: "case-studies-slug___en___default",
    path: "/case-studies/:slug()",
    meta: _91slug_93ri2DYrczfyMeta || {},
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/case-studies/[slug].vue")
  },
  {
    name: "case-studies-slug___en",
    path: "/en/case-studies/:slug()",
    meta: _91slug_93ri2DYrczfyMeta || {},
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/case-studies/[slug].vue")
  },
  {
    name: "case-studies-slug___de",
    path: "/de/case-studies/:slug()",
    meta: _91slug_93ri2DYrczfyMeta || {},
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/case-studies/[slug].vue")
  },
  {
    name: "case-studies-slug___es",
    path: "/es/case-studies/:slug()",
    meta: _91slug_93ri2DYrczfyMeta || {},
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/case-studies/[slug].vue")
  },
  {
    name: "case-studies-slug___it",
    path: "/it/case-studies/:slug()",
    meta: _91slug_93ri2DYrczfyMeta || {},
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/case-studies/[slug].vue")
  },
  {
    name: "case-studies-slug___pl",
    path: "/pl/case-studies/:slug()",
    meta: _91slug_93ri2DYrczfyMeta || {},
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/case-studies/[slug].vue")
  },
  {
    name: "case-studies___fr",
    path: "/fr/case-studies",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/case-studies/index.vue")
  },
  {
    name: "case-studies___en___default",
    path: "/case-studies",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/case-studies/index.vue")
  },
  {
    name: "case-studies___en",
    path: "/en/case-studies",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/case-studies/index.vue")
  },
  {
    name: "case-studies___de",
    path: "/de/case-studies",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/case-studies/index.vue")
  },
  {
    name: "case-studies___es",
    path: "/es/case-studies",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/case-studies/index.vue")
  },
  {
    name: "case-studies___it",
    path: "/it/case-studies",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/case-studies/index.vue")
  },
  {
    name: "case-studies___pl",
    path: "/pl/case-studies",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/case-studies/index.vue")
  },
  {
    name: "cities___fr",
    path: "/fr/cities",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/cities.vue")
  },
  {
    name: "cities___en___default",
    path: "/cities",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/cities.vue")
  },
  {
    name: "cities___en",
    path: "/en/cities",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/cities.vue")
  },
  {
    name: "cities___de",
    path: "/de/cities",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/cities.vue")
  },
  {
    name: "cities___es",
    path: "/es/cities",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/cities.vue")
  },
  {
    name: "cities___it",
    path: "/it/cities",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/cities.vue")
  },
  {
    name: "cities___pl",
    path: "/pl/cities",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/cities.vue")
  },
  {
    name: "contact___fr",
    path: "/fr/contact",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/contact.vue")
  },
  {
    name: "contact___en___default",
    path: "/contact",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/contact.vue")
  },
  {
    name: "contact___en",
    path: "/en/contact",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/contact.vue")
  },
  {
    name: "contact___de",
    path: "/de/contact",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/contact.vue")
  },
  {
    name: "contact___es",
    path: "/es/contact",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/contact.vue")
  },
  {
    name: "contact___it",
    path: "/it/contact",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/contact.vue")
  },
  {
    name: "contact___pl",
    path: "/pl/contact",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/contact.vue")
  },
  {
    name: "electric-bike___fr",
    path: "/fr/electric-bike",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/electric-bike.vue")
  },
  {
    name: "electric-bike___en___default",
    path: "/electric-bike",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/electric-bike.vue")
  },
  {
    name: "electric-bike___en",
    path: "/en/electric-bike",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/electric-bike.vue")
  },
  {
    name: "electric-bike___de",
    path: "/de/electric-bike",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/electric-bike.vue")
  },
  {
    name: "electric-bike___es",
    path: "/es/electric-bike",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/electric-bike.vue")
  },
  {
    name: "electric-bike___it",
    path: "/it/electric-bike",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/electric-bike.vue")
  },
  {
    name: "electric-bike___pl",
    path: "/pl/electric-bike",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/electric-bike.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/index.vue")
  },
  {
    name: "index___en___default",
    path: "/",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/index.vue")
  },
  {
    name: "index___it",
    path: "/it",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/index.vue")
  },
  {
    name: "index___pl",
    path: "/pl",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/index.vue")
  },
  {
    name: "internal-confirmation___fr",
    path: "/fr/internal/confirmation",
    meta: confirmationbW62zJ0AorMeta || {},
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/internal/confirmation.vue")
  },
  {
    name: "internal-confirmation___en___default",
    path: "/internal/confirmation",
    meta: confirmationbW62zJ0AorMeta || {},
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/internal/confirmation.vue")
  },
  {
    name: "internal-confirmation___en",
    path: "/en/internal/confirmation",
    meta: confirmationbW62zJ0AorMeta || {},
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/internal/confirmation.vue")
  },
  {
    name: "internal-confirmation___de",
    path: "/de/internal/confirmation",
    meta: confirmationbW62zJ0AorMeta || {},
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/internal/confirmation.vue")
  },
  {
    name: "internal-confirmation___es",
    path: "/es/internal/confirmation",
    meta: confirmationbW62zJ0AorMeta || {},
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/internal/confirmation.vue")
  },
  {
    name: "internal-confirmation___it",
    path: "/it/internal/confirmation",
    meta: confirmationbW62zJ0AorMeta || {},
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/internal/confirmation.vue")
  },
  {
    name: "internal-confirmation___pl",
    path: "/pl/internal/confirmation",
    meta: confirmationbW62zJ0AorMeta || {},
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/internal/confirmation.vue")
  },
  {
    name: "internal-loader___fr",
    path: "/fr/internal/loader",
    meta: loaderiatEv1QpaNMeta || {},
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/internal/loader.vue")
  },
  {
    name: "internal-loader___en___default",
    path: "/internal/loader",
    meta: loaderiatEv1QpaNMeta || {},
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/internal/loader.vue")
  },
  {
    name: "internal-loader___en",
    path: "/en/internal/loader",
    meta: loaderiatEv1QpaNMeta || {},
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/internal/loader.vue")
  },
  {
    name: "internal-loader___de",
    path: "/de/internal/loader",
    meta: loaderiatEv1QpaNMeta || {},
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/internal/loader.vue")
  },
  {
    name: "internal-loader___es",
    path: "/es/internal/loader",
    meta: loaderiatEv1QpaNMeta || {},
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/internal/loader.vue")
  },
  {
    name: "internal-loader___it",
    path: "/it/internal/loader",
    meta: loaderiatEv1QpaNMeta || {},
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/internal/loader.vue")
  },
  {
    name: "internal-loader___pl",
    path: "/pl/internal/loader",
    meta: loaderiatEv1QpaNMeta || {},
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/internal/loader.vue")
  },
  {
    name: "landing-slug___fr",
    path: "/fr/landing/:slug()",
    meta: _91slug_9341mM200tSyMeta || {},
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/landing/[slug].vue")
  },
  {
    name: "landing-slug___en___default",
    path: "/landing/:slug()",
    meta: _91slug_9341mM200tSyMeta || {},
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/landing/[slug].vue")
  },
  {
    name: "landing-slug___en",
    path: "/en/landing/:slug()",
    meta: _91slug_9341mM200tSyMeta || {},
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/landing/[slug].vue")
  },
  {
    name: "landing-slug___de",
    path: "/de/landing/:slug()",
    meta: _91slug_9341mM200tSyMeta || {},
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/landing/[slug].vue")
  },
  {
    name: "landing-slug___es",
    path: "/es/landing/:slug()",
    meta: _91slug_9341mM200tSyMeta || {},
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/landing/[slug].vue")
  },
  {
    name: "landing-slug___it",
    path: "/it/landing/:slug()",
    meta: _91slug_9341mM200tSyMeta || {},
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/landing/[slug].vue")
  },
  {
    name: "landing-slug___pl",
    path: "/pl/landing/:slug()",
    meta: _91slug_9341mM200tSyMeta || {},
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/landing/[slug].vue")
  },
  {
    name: "leasing___fr",
    path: "/fr/leasing",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/leasing.vue")
  },
  {
    name: "leasing___en___default",
    path: "/leasing",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/leasing.vue")
  },
  {
    name: "leasing___en",
    path: "/en/leasing",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/leasing.vue")
  },
  {
    name: "leasing___de",
    path: "/de/leasing",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/leasing.vue")
  },
  {
    name: "leasing___es",
    path: "/es/leasing",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/leasing.vue")
  },
  {
    name: "leasing___it",
    path: "/it/leasing",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/leasing.vue")
  },
  {
    name: "leasing___pl",
    path: "/pl/leasing",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/leasing.vue")
  },
  {
    name: "legal___fr",
    path: "/fr/legal",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/legal.vue")
  },
  {
    name: "legal___en___default",
    path: "/legal",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/legal.vue")
  },
  {
    name: "legal___en",
    path: "/en/legal",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/legal.vue")
  },
  {
    name: "legal___de",
    path: "/de/legal",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/legal.vue")
  },
  {
    name: "legal___es",
    path: "/es/legal",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/legal.vue")
  },
  {
    name: "legal___it",
    path: "/it/legal",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/legal.vue")
  },
  {
    name: "legal___pl",
    path: "/pl/legal",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/legal.vue")
  },
  {
    name: "news-slug___fr",
    path: "/fr/news/:slug()",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/news/[slug].vue")
  },
  {
    name: "news-slug___en___default",
    path: "/news/:slug()",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/news/[slug].vue")
  },
  {
    name: "news-slug___en",
    path: "/en/news/:slug()",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/news/[slug].vue")
  },
  {
    name: "news-slug___de",
    path: "/de/news/:slug()",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/news/[slug].vue")
  },
  {
    name: "news-slug___es",
    path: "/es/news/:slug()",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/news/[slug].vue")
  },
  {
    name: "news-slug___it",
    path: "/it/news/:slug()",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/news/[slug].vue")
  },
  {
    name: "news-slug___pl",
    path: "/pl/news/:slug()",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/news/[slug].vue")
  },
  {
    name: "news___fr",
    path: "/fr/news",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/news/index.vue")
  },
  {
    name: "news___en___default",
    path: "/news",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/news/index.vue")
  },
  {
    name: "news___en",
    path: "/en/news",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/news/index.vue")
  },
  {
    name: "news___de",
    path: "/de/news",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/news/index.vue")
  },
  {
    name: "news___es",
    path: "/es/news",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/news/index.vue")
  },
  {
    name: "news___it",
    path: "/it/news",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/news/index.vue")
  },
  {
    name: "news___pl",
    path: "/pl/news",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/news/index.vue")
  },
  {
    name: "operators___fr",
    path: "/fr/operators",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/operators.vue")
  },
  {
    name: "operators___en___default",
    path: "/operators",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/operators.vue")
  },
  {
    name: "operators___en",
    path: "/en/operators",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/operators.vue")
  },
  {
    name: "operators___de",
    path: "/de/operators",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/operators.vue")
  },
  {
    name: "operators___es",
    path: "/es/operators",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/operators.vue")
  },
  {
    name: "operators___it",
    path: "/it/operators",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/operators.vue")
  },
  {
    name: "operators___pl",
    path: "/pl/operators",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/operators.vue")
  },
  {
    name: "privacy___fr",
    path: "/fr/privacy",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/privacy.vue")
  },
  {
    name: "privacy___en___default",
    path: "/privacy",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/privacy.vue")
  },
  {
    name: "privacy___en",
    path: "/en/privacy",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/privacy.vue")
  },
  {
    name: "privacy___de",
    path: "/de/privacy",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/privacy.vue")
  },
  {
    name: "privacy___es",
    path: "/es/privacy",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/privacy.vue")
  },
  {
    name: "privacy___it",
    path: "/it/privacy",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/privacy.vue")
  },
  {
    name: "privacy___pl",
    path: "/pl/privacy",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/privacy.vue")
  },
  {
    name: "resources-blog-slug___fr",
    path: "/fr/resources/blog/:slug()",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/resources/blog/[slug].vue")
  },
  {
    name: "resources-blog-slug___en___default",
    path: "/resources/blog/:slug()",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/resources/blog/[slug].vue")
  },
  {
    name: "resources-blog-slug___en",
    path: "/en/resources/blog/:slug()",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/resources/blog/[slug].vue")
  },
  {
    name: "resources-blog-slug___de",
    path: "/de/resources/blog/:slug()",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/resources/blog/[slug].vue")
  },
  {
    name: "resources-blog-slug___es",
    path: "/es/resources/blog/:slug()",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/resources/blog/[slug].vue")
  },
  {
    name: "resources-blog-slug___it",
    path: "/it/resources/blog/:slug()",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/resources/blog/[slug].vue")
  },
  {
    name: "resources-blog-slug___pl",
    path: "/pl/resources/blog/:slug()",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/resources/blog/[slug].vue")
  },
  {
    name: "resources-blog___fr",
    path: "/fr/resources/blog",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/resources/blog/index.vue")
  },
  {
    name: "resources-blog___en___default",
    path: "/resources/blog",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/resources/blog/index.vue")
  },
  {
    name: "resources-blog___en",
    path: "/en/resources/blog",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/resources/blog/index.vue")
  },
  {
    name: "resources-blog___de",
    path: "/de/resources/blog",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/resources/blog/index.vue")
  },
  {
    name: "resources-blog___es",
    path: "/es/resources/blog",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/resources/blog/index.vue")
  },
  {
    name: "resources-blog___it",
    path: "/it/resources/blog",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/resources/blog/index.vue")
  },
  {
    name: "resources-blog___pl",
    path: "/pl/resources/blog",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/resources/blog/index.vue")
  },
  {
    name: "resources-guides-slug___fr",
    path: "/fr/resources/guides/:slug()",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/resources/guides/[slug].vue")
  },
  {
    name: "resources-guides-slug___en___default",
    path: "/resources/guides/:slug()",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/resources/guides/[slug].vue")
  },
  {
    name: "resources-guides-slug___en",
    path: "/en/resources/guides/:slug()",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/resources/guides/[slug].vue")
  },
  {
    name: "resources-guides-slug___de",
    path: "/de/resources/guides/:slug()",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/resources/guides/[slug].vue")
  },
  {
    name: "resources-guides-slug___es",
    path: "/es/resources/guides/:slug()",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/resources/guides/[slug].vue")
  },
  {
    name: "resources-guides-slug___it",
    path: "/it/resources/guides/:slug()",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/resources/guides/[slug].vue")
  },
  {
    name: "resources-guides-slug___pl",
    path: "/pl/resources/guides/:slug()",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/resources/guides/[slug].vue")
  },
  {
    name: "resources-guides___fr",
    path: "/fr/resources/guides",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/resources/guides/index.vue")
  },
  {
    name: "resources-guides___en___default",
    path: "/resources/guides",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/resources/guides/index.vue")
  },
  {
    name: "resources-guides___en",
    path: "/en/resources/guides",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/resources/guides/index.vue")
  },
  {
    name: "resources-guides___de",
    path: "/de/resources/guides",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/resources/guides/index.vue")
  },
  {
    name: "resources-guides___es",
    path: "/es/resources/guides",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/resources/guides/index.vue")
  },
  {
    name: "resources-guides___it",
    path: "/it/resources/guides",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/resources/guides/index.vue")
  },
  {
    name: "resources-guides___pl",
    path: "/pl/resources/guides",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/resources/guides/index.vue")
  },
  {
    name: "resources___fr",
    path: "/fr/resources",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/resources/index.vue")
  },
  {
    name: "resources___en___default",
    path: "/resources",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/resources/index.vue")
  },
  {
    name: "resources___en",
    path: "/en/resources",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/resources/index.vue")
  },
  {
    name: "resources___de",
    path: "/de/resources",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/resources/index.vue")
  },
  {
    name: "resources___es",
    path: "/es/resources",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/resources/index.vue")
  },
  {
    name: "resources___it",
    path: "/it/resources",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/resources/index.vue")
  },
  {
    name: "resources___pl",
    path: "/pl/resources",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/resources/index.vue")
  },
  {
    name: "sharing___fr",
    path: "/fr/sharing",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/sharing.vue")
  },
  {
    name: "sharing___en___default",
    path: "/sharing",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/sharing.vue")
  },
  {
    name: "sharing___en",
    path: "/en/sharing",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/sharing.vue")
  },
  {
    name: "sharing___de",
    path: "/de/sharing",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/sharing.vue")
  },
  {
    name: "sharing___es",
    path: "/es/sharing",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/sharing.vue")
  },
  {
    name: "sharing___it",
    path: "/it/sharing",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/sharing.vue")
  },
  {
    name: "sharing___pl",
    path: "/pl/sharing",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/sharing.vue")
  },
  {
    name: "smart-station___fr",
    path: "/fr/smart-station",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/smart-station.vue")
  },
  {
    name: "smart-station___en___default",
    path: "/smart-station",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/smart-station.vue")
  },
  {
    name: "smart-station___en",
    path: "/en/smart-station",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/smart-station.vue")
  },
  {
    name: "smart-station___de",
    path: "/de/smart-station",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/smart-station.vue")
  },
  {
    name: "smart-station___es",
    path: "/es/smart-station",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/smart-station.vue")
  },
  {
    name: "smart-station___it",
    path: "/it/smart-station",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/smart-station.vue")
  },
  {
    name: "smart-station___pl",
    path: "/pl/smart-station",
    component: () => import("/tmp/build/ad8838f6/corporate-website-nuxt3-pr/pages/smart-station.vue")
  }
]