import { joinURL, withQuery } from 'ufo';

import { locales } from '@/config/locales';
import { version } from '@/package.json';

/**
 * Composable that initialize generic SEO meta tags using useHead
 * @param options - UseSeoMeta options
 * @param options.title - SEO Title
 * @param options.description - SEO description
 * @param options.imageUrl - SEO image URL. Can be a static asset, starting with `/` or an external URL, starting with `http(s)://`
 */
export function useSeoMetaHead({
  title,
  description,
  imageUrl,
}: {
  title: string;
  description: string;
  imageUrl: string;
}): void {
  // Get url and origin
  let url = '';
  let origin = '';
  if (import.meta.server) {
    const headers = useRequestHeaders();
    const { ssrContext } = useNuxtApp();

    origin = 'https://' + headers.host;
    url = origin + ssrContext?.url;
  }
  if (import.meta.client) {
    origin = window.location.origin;
    url = origin + window.location.pathname;
  }
  const isExternalImage = /^https?:\/\//.test(imageUrl);
  const absoluteImageUrl = isExternalImage
    ? imageUrl
    : withQuery(joinURL(origin, imageUrl), { v: version });

  const { locale } = useI18n();

  useHead({
    title,
    meta: [
      { name: 'description', content: description },
      // open graph protocol
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: url },
      { property: 'og:image', content: absoluteImageUrl },
      { property: 'og:locale', content: locale.value },
      // i18n og meta
      ...locales
        .filter(_locale => _locale !== locale.value)
        .map(_locale => ({
          property: 'og:locale:alternate',
          content: _locale,
          // These guys need an id otherwise they cannot be multiple
          id: 'og-alt-' + _locale,
        })),
      // twitter
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:site', content: '@zoov_eu' },
      { name: 'twitter:title', content: title },
      { name: 'twitter:description', content: description },
      { name: 'twitter:image:src', content: absoluteImageUrl },
    ],
    link: [
      // i18n links
      { rel: 'canonical', href: url },
      ...locales
        .filter(_locale => _locale !== locale.value)
        .map(_locale => ({
          rel: 'alternate',
          hreflang: locale,
          href: url.replace(new RegExp(`/${locale.value}/`), `/${locale}/`),
        })),
    ],
  });
}
