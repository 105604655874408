import { stringify } from 'qs';

type UseStrapiOptions<T> = {
  /**
   * Array of locales to restrict for the request. If the current user lang is not in the subset, take the Strapi default locale
   */
  localesSubset?: string[];
  /**
   * If true, use any locale given in localesSubset instead of taking the current user lang
   */
  anyLocale?: boolean;
  /**
   * Strapi API query params (filtering, sorting...)
   */
  queryParams?: PickStrapiRequestParams<T>;
};

type PickStrapiRequestParams<T> = T extends keyof StrapiRequestParamsMap
  ? StrapiRequestParamsMap[T]
  : StrapiRequestParams;

type StrapiRequestParamsMap = {
  'blog-posts': BlogPostStrapiRequestParams;
};

export interface StrapiContentTypeMap {
  guides: GuideContentType[];
  'blog-posts': BlogPostContentType[];
  'embed-pages': EmbedPageContentType[];
  navigations: NavigationContentType[];
  'privacy-policy': PrivacyPolicyContentType;
  'legal-notice': LegalNoticesContentType;
  homepage: HomepageContentType;
  audiences: AudienceContentType[];
  topics: TopicContentType[];
  city: AudiencePageContentType;
  operator: AudiencePageContentType;
  'resources-home': ResourcesHomePageContentType;
  'turnkey-service-sharing': TurnkeyServicePageContentType;
  'turnkey-service-leasing': TurnkeyServicePageContentType;
  'turnkey-service-train-station': TurnkeyServicePageContentType;
  'product-electric-bike': ProductPageContentType;
  'product-smart-station': ProductPageContentType;
  'use-case-home': UseCaseHomeContentType;
  'use-cases': UseCaseContentType[];
}

type StrapApiRoute = keyof StrapiContentTypeMap;

type StrapiRawDataType<T, U> = T extends keyof StrapiContentTypeMap
  ? U extends string | number
    ? StrapiResponse<StrapiContentTypeMap[T]>['data'] extends (infer V)[]
      ? { data: V; meta: Record<string, unknown> }
      : StrapiResponse<StrapiContentTypeMap[T]>
    : StrapiResponse<StrapiContentTypeMap[T]>
  : never;

type UseAsyncDataReturn<Data, Error> = ReturnType<
  typeof useAsyncData<Data, Error>
>;

/**
 * Fetch data from Strapi REST API
 * @param apiRoute - Strapi API route, plugged to the underlying content type
 * @param options - Options, @see {UseStrapiOptions}
 * @returns Promise with raw Strapi response
 */
export async function useStrapi<T extends StrapApiRoute, U>(
  apiRoute: T,
  options?: UseStrapiOptions<T>,
  slugOrId?: U
): Promise<UseAsyncDataReturn<StrapiRawDataType<T, U>, Error | null>> {
  const config = useRuntimeConfig();

  const strapiLocale = await useStrapiLocale(options?.localesSubset);

  return useAsyncData(
    // Key is built from apiRoute + queryParams to prevent unwanted requests deduplication
    apiRoute + JSON.stringify(options?.queryParams),
    async () => {
      const query = computed(() => {
        const forceDraftVisibility =
          config.public.strapiDraftVisibility === true;

        const queryParams = stringify(
          {
            ...(options?.anyLocale
              ? { locale: options?.localesSubset }
              : strapiLocale.value && { locale: strapiLocale.value }),
            ...options?.queryParams,
            ...(forceDraftVisibility && {
              publicationState: 'preview',
            }),
          },
          {
            // Prettify the output by not encoding the keys
            encodeValuesOnly: true,
          }
        );
        const pathParams = slugOrId ? `/${slugOrId}` : '';

        return `${apiRoute}${pathParams}?${queryParams}`;
      });
      return $fetch<StrapiRawDataType<T, U>>(query.value, {
        baseURL: config.public.strapiBaseUrl,
      });
    },
    {
      watch: [strapiLocale],
    }
  );
}
