<template lang="pug">
Transition(
  name="FcPopup--fade"
  :duration="200"
)
  .FcPopup(v-show="isVisible")
    .FcPopup__mask(@click="closeOutside && closeModal()")
      .FcPopup__card(:style="cardStyle")
        slot(name="card")
        FButton.FcPopup__closeButton(
          v-if="closeButton"
          icon
          @click="closeModal"
        )
          FIcon(name="close")
        .FcPopup__card__wrapper(v-if="!slots.card")
          .FcPopup__card__content
            slot(name="content")
          .FcPopup__card__actions
            slot(
              name="actions"
              :close="closeModal"
            )
          span.FcPopup__card__countdown(v-if="!!countdown") {{ t('popup__countdown', { seconds_left: secondsLeft }) }}
</template>

<style lang="stylus">
.FcPopup__mask
  display flex
  justify-content center
  align-items center
  position fixed
  top 0
  left 0
  width 100%
  height 100%
  background 'rgba(%s, 0.6)' % var(--color--neutral--dark-4--rgb)
  z-index 20

.FcPopup__closeButton
  position absolute
  top rem(-20)
  right rem(-20)

.FcPopup__card
  position relative
  width var(--FcPopup--card--width, 'calc(100% - %s)' % rem(56))
  max-width var(--FcPopup--card--maxWidth)
  border-radius rem(8)
  max-height 'calc(100% - %s)' % rem(56)

.FcPopup__card__wrapper
  padding rem(40) rem(56)
  border-radius rem(8)
  background white
  color var(--color--secondary--dark-2)
  display flex
  flex-direction column
  gap rem(24)
  overflow auto

.FcPopup__card__actions
  display flex
  column-gap rem(16)
  row-gap rem(16)
  flex-wrap wrap

.FcPopup__card__countdown
  use-font('caption')
  color var(--color--neutral--dark-3)
  margin-bottom rem(-4)

.FcPopup--fade-leave-active
  transition opacity 0.2s ease

.FcPopup--fade-leave-to
  opacity 0
</style>

<script setup lang="ts">
import { noop } from '@/lib/utils';

import type { WatchStopHandle } from 'vue';

export interface FcPopupProps {
  /**
   * Handles the modal visibility
   */
  modelValue?: boolean;
  /**
   * Time in seconds until the popup closes itself automatically, default is `0` (persistent)
   */
  countdown?: number;
  /**
   * Show a close button on top-right corner
   */
  closeButton?: boolean;
  /**
   * Whether the popup can be closed by clicking outside
   */
  closeOutside?: boolean;
  /**
   * Width of the modal, default fills the screen with margins
   */
  width?: string | number;
  /**
   * Max width of the modal, `none` by default
   */
  maxWidth?: string | number;
}

const props = withDefaults(defineProps<FcPopupProps>(), {
  modelValue: true,
  countdown: 0,
  width: undefined,
  maxWidth: 'none',
});

const cardStyle = computed(
  (): Style => ({
    '--FcPopup--card--width': genSize(props.width),
    '--FcPopup--card--maxWidth': genSize(props.maxWidth),
  })
);

const { t } = useI18n();

const isVisible = useVModelProxy({ props });
const secondsLeft = ref(props.countdown);
let unwatchCountdown: WatchStopHandle = noop;

watch(
  isVisible,
  value => {
    if (value && props.countdown > 0) {
      let countdownInterval = useIntervalFn(() => secondsLeft.value--, 1000);
      unwatchCountdown = watch(secondsLeft, newValue => {
        if (newValue <= 0) {
          closeModal();
          countdownInterval.pause();
        }
      });
    } else {
      unwatchCountdown();
    }
  },
  { immediate: true }
);

function closeModal(): void {
  isVisible.value = false;
}

const slots = defineSlots<{
  /**
   * Renders content in the card without any styling nor spacing
   * @example used to display full-sized content such as images
   */
  card(): SlotContent;
  /**
   * The content of the popup (spaced inside the card)
   */
  content(): SlotContent;
  /**
   * Actions to display at the bottom of the popup
   * @param scope.close - Closes the popup
   */
  actions(scope: { close: () => void }): SlotContent;
}>();
</script>
