import { mapApiDataToEmbedPage } from '@/lib/cms-mappers';
import { locales } from '@/config/locales';

import type { EmbedPage } from '@/lib/cms-mappers/embed-page.mappers';

/**
 * Returns page data fetched from CMS (Strapi), based on its slug
 * @param slug - Page slug
 */
export async function useCmsEmbedPage(
  slug: string
): UseCmsResponse<EmbedPage | null> {
  const { data, error, pending, refresh } = await useStrapi('embed-pages', {
    localesSubset: locales,
    anyLocale: true,
    queryParams: {
      filters: { slug },
      populate: ['image'],
    },
  });

  const mappedData = computed(() => mapApiDataToEmbedPage(data.value?.data));
  return { data: mappedData, error, pending, refresh };
}
