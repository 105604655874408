export function initHubspotTracking() {
  /**
   * Old instance
   * @deprecated Remove when Hubspot is fully migrated
   */
  const hubspotScriptElOld = document.createElement('script');
  hubspotScriptElOld.id = 'hs-script-loader';
  hubspotScriptElOld.type = 'text/javascript';
  hubspotScriptElOld.src = 'https://js.hs-scripts.com/4729296.js';
  hubspotScriptElOld.async = true;
  if (document.body) document.body.appendChild(hubspotScriptElOld);

  /**
   * New instance
   */
  const hubspotScriptEl = document.createElement('script');
  hubspotScriptEl.id = 'hs-script-loader';
  hubspotScriptEl.type = 'text/javascript';
  hubspotScriptEl.src = 'https://js-eu1.hs-scripts.com/139755666.js';
  hubspotScriptEl.async = true;
  if (document.body) document.body.appendChild(hubspotScriptEl);
}
