import { joinURL, withFragment } from 'ufo';

import type { RouteLocationRaw } from 'vue-router';

export interface UseI18nPathReturn {
  /**
   * The final internationalized to route with prefixed path
   */
  i18nPath: Ref<string | undefined>;
  /**
   * If the input route is already localized or has been prefixed, the corresponding hreflang is returned
   */
  hreflang: Ref<string | undefined>;
}

/**
 * Generate properly prefixed route path from a given route
 * @param to - Route to handle
 */
export function useI18nPath(
  to: MaybeRefOrGetter<RouteLocationRaw | null>
): UseI18nPathReturn {
  // Get the current language
  const router = useRouter();
  const { locale } = useI18n();

  const i18nPath = ref<string>();
  const hreflang = ref<string>();

  watchImmediate(locale, () => {
    const _to = toValue(to);
    if (_to === null || _to === undefined) return;

    const { path, hash } = router.resolve(_to);
    const matchedLocalizedPath = path.match(/^\/([a-z]{2})(?:\/|$)/);
    const isPathLocalized = !!matchedLocalizedPath;
    i18nPath.value = isPathLocalized
      ? withFragment(path, hash)
      : withFragment(joinURL('/', locale.value, path), hash);

    // Set final hreflang
    hreflang.value = isPathLocalized ? matchedLocalizedPath[1] : locale.value;
  });

  return {
    i18nPath,
    hreflang,
  };
}
