import { redirections, smooveRedirections } from '@/config/redirections';

import type { RouteLocationNormalizedGeneric } from 'vue-router';

/**
 * 1- Redirect to the correct path if desired route matches the redirections map
 * 2- Add a language prefix to the url and redirect to the right url if the prefix is wrong
 */
export default defineNuxtRouteMiddleware((to, from) => {
  const app = useNuxtApp();
  const locale = app.$i18n.locale;
  const fromLang = from.params.lang as string;

  // Routes redirections
  const [_, __, unlocalizedPath] =
    /^\/?([a-z]{2})?(\/.+)/.exec(to.path) || (Array(2) as string[]);

  // Remove trailing slash, query string and fragments
  const rawUnlocalizedPath = unlocalizedPath
    ?.replace(/\/$/, '')
    .replace(/#.*$/, '')
    .replace(/\?.*$/, '');

  // Specifics redirections of users coming from smoove website
  const redirectedFrom = String(to.query.redirected_from);
  const smooveRegex =
    /(?:https?:\/\/)?(?:www\.)?smoove(?:-[a-z]+)?\.[a-z]+(?:[a-zA-Z0-9\?%=_\-\+\/#]*)/;
  const isSmooveUrl = smooveRegex.test(redirectedFrom);

  if (isSmooveUrl) {
    // Redirect to the appropriate URL if there is a specific match,
    // else redirect to the homepage
    const target =
      smooveRedirections[redirectedFrom] ?? smooveRedirections['default'];
    return navigateTo(
      {
        path: target,
        query: { smoove_redirection: 1 },
      },
      { replace: true }
    );
  }

  if (Object.keys(redirections).includes(rawUnlocalizedPath)) {
    const redirectPath = rawUnlocalizedPath as keyof typeof redirections;
    const redirectRoute: Partial<RouteLocationNormalizedGeneric> = {
      path: redirections[redirectPath],
      params: { ...to.params, lang: fromLang },
      query: to.query,
    };
    return navigateTo(redirectRoute, { replace: true });
  }

  // redirect to previous, or user language if it is a "naked" url (no localized meta)
  if (/___default$/.test(String(to.name))) {
    const newName = String(to.name).replace(
      /[a-z]{2}___default$/,
      fromLang || locale.value
    );
    return navigateTo(
      {
        name: newName,
        params: to.params,
        query: to.query,
      },
      { replace: true }
    );
  }
});
